import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { BinReservesInfo } from '@/types/liquidity';
import { useCurrencies, usePoolsSoloStore, useTokensStore } from '@/store';
import { fetchBinPrices, fetchBinReservesForPools } from '@/service/CLApi';
import { ACTIVE_BIN_INDEX } from '@/constants/bin-range';
import { adjustPrice } from '@/utils/liquidity';
import { fetchAllSoloLiquidity } from '@/service/api';
import { useFetchBinAmounts } from '@/composables/liquidity/useFetchBinAmounts';
import { is_sorted } from '@/utils/contracts';
import { BinInfo } from '@/types/liquidity';
import { SOLO_RESOURCES_ACCOUNT } from '@/constants';

export const useBinReserves = defineStore('binReservesStore', () => {
  const { sortedStoredTokenEntities } = storeToRefs(useCurrencies());
  const { fetchBinAmounts } = useFetchBinAmounts();
  const tokensStore = useTokensStore();

  const binReserves = ref<BinReservesInfo[]>([]);
  const areBinReservesUpdated = ref(false);
  const binReservesLoader = ref<boolean>();
  const binAmounts = ref<BinInfo[]>([]);

  const poolStore = usePoolsSoloStore();

  async function updateBinReserves(collectionName: string) {
    binReservesLoader.value = true;
    try {
      const reserves = await fetchBinReservesForPools(collectionName);
      const binStep = poolStore.soloPools.find(
        (pool) => pool.collection_name === collectionName,
      )?.binStep;
      const activeBinId = poolStore.soloPools.find(
        (pool) => pool.collection_name === collectionName,
      )?.active_bin_id;

      if (!binStep || !activeBinId) return;

      const binIdsMap = Object.keys(reserves).reduce<Record<string, boolean>>(
        (acc, binId) => {
          acc[binId] = true;
          return acc;
        },
        {},
      );

      const bins: BinReservesInfo[] = Object.keys(binIdsMap).map((binId) => ({
        name: binId,
        reserves: reserves[binId] ?? [0, 0],
      }));

      bins.sort((a: any, b: any) => a.name - b.name);

      const binsIds = bins
        .map(({ name }) => Number(name))
        .filter((item) => Math.abs(activeBinId - item) <= ACTIVE_BIN_INDEX);

      const pricesArray = await fetchBinPrices(binsIds, binStep);

      const [tokenX, tokenY] = sortedStoredTokenEntities.value;
      if (!tokenX || !tokenY) return;

      const adjustedPrices = pricesArray.map((price) =>
        adjustPrice(price ?? 0, tokenX.decimals, tokenY.decimals, {
          mode: 'from-sc',
        }),
      );

      const mappedBins = bins.map((bin, idx) => {
        const priceId = binsIds.find((item) => item == +bin.name);

        if (priceId && priceId.toString() === bin.name) {
          const price = adjustedPrices[idx];
          bin.price = price;
        }
        return bin;
      });

      binReserves.value = mappedBins;
      areBinReservesUpdated.value = true;
      binReservesLoader.value = false;
    } catch (e) {
      console.error('updateBinReserves error', e);
    }
  }

  async function updateBinsAmounts(collectionName: string) {
    const pool = poolStore.soloPools.find(
      (pool) => pool.collection_name === collectionName,
    );

    if (!pool) return;

    const { coinX, coinY, binStep } = pool;

    const userBinsWithLiquidity = await fetchAllSoloLiquidity(
      SOLO_RESOURCES_ACCOUNT,
    );
    userBinsWithLiquidity.sort((a: any, b: any) => a.name - b.name);

    const decimalsFrom = tokensStore.getToken(coinX)?.decimals ?? 0;
    const decimalsTo = tokensStore.getToken(coinY)?.decimals ?? 0;

    const [decimalsX, decimalsY] = is_sorted(coinX, coinY)
      ? [decimalsFrom, decimalsTo]
      : [decimalsTo, decimalsFrom];

    const binAmounts_ = await fetchBinAmounts(
      coinX,
      coinY,
      binStep,
      userBinsWithLiquidity,
      decimalsX,
      decimalsY,
    );
    binAmounts.value = binAmounts_;
  }

  return {
    binReserves,
    binAmounts,
    areBinReservesUpdated,
    binReservesLoader,
    updateBinReserves,
    updateBinsAmounts,
  };
});
