import {
  APTOS_NODE_API_KEY,
  APTOS_INDEXER_API_URL,
  NODE_URL,
} from '@/constants';
import {
  Aptos,
  AptosConfig,
  Ed25519PublicKey,
  InputEntryFunctionData,
} from '@aptos-labs/ts-sdk';
import { useWalletPlugin } from './useWalletPlugin';

const SIMULATION_TRANSACTION_EXPIRATION_TIMESTEMP_SECS = 32425224034n;
const SIMULATION_TRANSACTION_PRIVATE_KEY =
  '0x00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000';

const SIMULATION_TRANSACTION_SIGNATURE = 'ed25519_signature';
const config = new AptosConfig({
  indexer: `${APTOS_INDEXER_API_URL}/v1/graphql`,
  fullnode: NODE_URL,
  clientConfig: {
    API_KEY: APTOS_NODE_API_KEY,
  },
});
const client = new Aptos(config);

export function useAptosV2Client() {
  const { account } = useWalletPlugin();

  const simulateTransaction = async (payload: InputEntryFunctionData) => {
    const compiledData = {
      sender: account.value?.address ?? '',
      max_gas_amount: 1n,
      gas_unit_price: 1n,
      expiration_timestamp_secs:
        SIMULATION_TRANSACTION_EXPIRATION_TIMESTEMP_SECS,
      payload: {
        ...payload,
      },
      signature: {
        type: SIMULATION_TRANSACTION_SIGNATURE,
        public_key: account.value?.publicKey,
        signature: SIMULATION_TRANSACTION_PRIVATE_KEY,
      },
    };

    const transaction = await client.transaction.build.simple({
      sender: (account.value?.address as string) ?? '',
      data: payload,
    });
    const [completed] = await client.transaction.simulate.simple({
      signerPublicKey: new Ed25519PublicKey(
        (account.value?.publicKey as string) ?? '',
      ),
      transaction: transaction,
      options: {
        estimateMaxGasAmount: true,
        estimateGasUnitPrice: true,
        estimatePrioritizedGasUnitPrice: false,
      },
    });

    return completed;
  };

  return {
    client,
    simulateTransaction,
  };
}
