import { GENESIS_BIN_ID_WITH_PRICE_1 } from '@/constants';
import { IPersistedPoolV1 } from '@/types/pools';
import { BigNumber } from 'bignumber.js';
import { d, decimalsMultiplier } from '.';

export function filterPools(
  pools: IPersistedPoolV1[],
  binStep: number | null,
): IPersistedPoolV1 | undefined {
  if (pools.length === 0) return undefined;
  if (pools.length === 1) return pools[0];
  if (pools.length > 1) {
    if (binStep) {
      for (const item of pools) {
        if (item.binStep === binStep) return item;
      }
      // if no such pool with stored binStep, return first item from poolsList
      return pools[0];
    } else {
      return pools[0];
    }
  }
}

/**
 * An analogue of the method from the documentation, in which bit
 * operations are replaced with arithmetic operations in the space of big numbers.
 * URL: https://www.notion.so/walletsofwings/d7f92dbe16ac47c3be1ca37b49fbe7b7?v=53e31a6511f9474cb5fe8eb1257a143a&p=e7b8e9ebea86438aa69f6d2a4baee0b6&pm=c
 * @param fp64
 * @returns
 */
export function fp64ToString(fp64: string) {
  const equivalentOf64Bits = new BigNumber(2).pow(64);

  const fp64Integer = new BigNumber(fp64).dividedToIntegerBy(
    equivalentOf64Bits,
  );

  const fraction = new BigNumber(fp64).minus(
    fp64Integer.multipliedBy(equivalentOf64Bits),
  );

  // 18 symbols after dot.
  const tenTo18 = new BigNumber('1000000000000000000');

  // Converting the fractional part to an integer with 18 digits after the dot.
  let fractionS = fraction
    .multipliedBy(tenTo18)
    .dividedToIntegerBy(equivalentOf64Bits);

  let floatString = `${fp64Integer.toString()}.${fractionS.toString()}`;

  /**
   * Addition of zeros to 9 decimal places after the dot.
   * The main case is when we expect values with significant zeros at the output (e.g "0.00000123456").
   * Or when there is only integer part (e.g 1 -> 1.00000000000000000).
   */
  fractionS = BigNumber.maximum(fractionS, 1).multipliedBy(10);

  while (fractionS.isLessThan(tenTo18)) {
    const dotIndex = floatString.indexOf('.');
    floatString =
      floatString.slice(0, dotIndex + 1) +
      '0' +
      floatString.slice(dotIndex + 1);
    fractionS = fractionS.multipliedBy(10);
  }

  return floatString;
}

export function ensureFp64ToNumber(fp64: string) {
  const priceCandidate = +fp64ToString(fp64);
  const numberPrice = Number.isNaN(priceCandidate) ? undefined : priceCandidate;

  return numberPrice;
}

/**
 * Convert a binId to the underlying price.
 * @see https://docs.traderjoexyz.com/concepts/concentrated-liquidity#bin-pricing
 * @param binId - current Bin Id.
 * @param binStep - binStep of the pair.
 * @param activeBinId - central bin id.
 * @return Price of the bin.
 */
export function getPriceFromId(binId: number, binStep: number): number {
  return (1 + binStep / 10_000) ** (binId - GENESIS_BIN_ID_WITH_PRICE_1);
}

export type TAdjustPriceMode = 'from-sc' | 'to-sc';
/**
 * docs: https://www.notion.so/walletsofwings/Price-Liquidity-e7b8e9ebea86438aa69f6d2a4baee0b6
 * @param desiredPrice
 * @param decimalsX
 * @param decimalsY
 * @returns
 */
export function adjustPrice(
  desiredPrice: number,
  decimalsX: number,
  decimalsY: number,
  options: {
    mode: TAdjustPriceMode;
  },
) {
  const decimalDiff = decimalsX - decimalsY;

  if (options.mode == 'from-sc') {
    return d(desiredPrice)
      .mul(d(10).pow(+decimalDiff))
      .toNumber();
  }

  return d(desiredPrice)
    .div(d(10).pow(+decimalDiff))
    .toNumber();
}

/**
 * Calculate X and Y tokens count in bin
 * @param xReserves
 * @param yReserves
 * @param binLiquidity
 * @param price — token relative price
 * @param yTokenDecimals
 * @returns X and Y amounts
 */
export function getBinAmounts(
  xReserves: number,
  yReserves: number,
  binLiquidity: number,
  price: number,
  yTokenDecimals: number,
) {
  const totalReserves = +d(xReserves).plus(yReserves);

  const xAmount = +d(binLiquidity)
    .div(totalReserves)
    .mul(xReserves)
    .div(price)
    .mul(decimalsMultiplier(yTokenDecimals));

  const yAmount = +d(binLiquidity).div(totalReserves).mul(yReserves);

  return [xAmount, yAmount];
}
