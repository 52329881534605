import { ensureFp64ToNumber } from '@/utils/liquidity';
import { createFuncString } from './swapApi';
import { CL_API, MODULES_V1_ACCOUNT, REST_URL } from '@/constants';
import { AptosClient } from 'aptos';
import { useMemoize } from '@vueuse/core';

const aptosClient = new AptosClient(REST_URL);

export const apiModules = {
  router: {
    moduleName: 'router',
    func: {
      getPriceFromBinId: 'get_price_from_bin_id',
      getPricesFromBinIds: 'get_prices_from_bin_ids',
    },
  },
};

/**
 * Returns converted bin price by binId from u128 to the number type.
 * This price is correct within the framework of a smart contract. This means that it is necessary to adjust it in accordance with the documentation
 * https://www.notion.so/walletsofwings/Price-Liquidity-e7b8e9ebea86438aa69f6d2a4baee0b6#ad4f280f89e64882b3100041d372cb10
 * @param binId
 * @param binStep
 * @returns
 */
export async function fetchBinPrice(binId: number, binStep: number) {
  const priceResponse = await memoFetchPrice(binId, binStep);

  if (priceResponse == undefined) {
    throw Error(`Couldn't get a price for bin ${binId} with step ${binStep}`);
  }

  const price = ensureFp64ToNumber(priceResponse);

  return price;
}

/**
 * Returns converted bin prices by binIds from u128 to the number type.
 * This price is correct within the framework of a smart contract. This means that it is necessary to adjust it in accordance with the documentation
 * https://www.notion.so/walletsofwings/Price-Liquidity-e7b8e9ebea86438aa69f6d2a4baee0b6#ad4f280f89e64882b3100041d372cb10
 * @param binIds
 * @param binStep
 * @returns
 */
export async function fetchBinPrices(binIds: number[], binStep: number) {
  const priceResponse = await memoFetchPrices(binIds, binStep);

  if (priceResponse == undefined) {
    throw Error(`Couldn't get a price for bin ${binIds} with step ${binStep}`);
  }

  const prices = priceResponse.map((u128Price) =>
    ensureFp64ToNumber(u128Price),
  );

  return prices;
}

export const fetchRawBinPrice = async (bin_id: number, bin_step: number) => {
  const {
    moduleName,
    func: { getPriceFromBinId: viewName },
  } = apiModules.router;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, viewName);

  const payload = {
    function: func_id,
    type_arguments: [],
    arguments: [bin_id, bin_step],
  };

  try {
    const response = await aptosClient.view(payload);
    return response[0] as string | undefined;
  } catch (err) {
    console.error(err);
  }
};

export const fetchRawBinPrices = async (bin_id: number[], bin_step: number) => {
  const {
    moduleName,
    func: { getPricesFromBinIds: viewName },
  } = apiModules.router;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, viewName);

  const payload = {
    function: func_id,
    type_arguments: [],
    arguments: [bin_id, bin_step],
  };

  try {
    const response = await aptosClient.view(payload);
    return response[0] as string[] | undefined;
  } catch (err) {
    console.error(err);
  }
};

export const memoFetchPrices = useMemoize(
  async (binIds: number[], binStep: number) =>
    fetchRawBinPrices(binIds, binStep),
);

export const memoFetchPrice = useMemoize(
  async (binId: number, binStep: number) => fetchRawBinPrice(binId, binStep),
);

export const fetchBinReservesForPools = async (collectionName: string) => {
  const encoded = encodeURIComponent(collectionName);
  try {
    const response = await fetch(`${CL_API}reserves/${encoded}`);
    return await response.json();
  } catch (e) {
    console.error('fetchBinReserves request error');
  }
};

export const fetchPools = async () => {
  try {
    const response = await fetch(`${CL_API}pools`);
    return await response.json();
  } catch (e) {
    console.error('fetchBinReserves request error');
  }
};
