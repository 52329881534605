import { MODULES_V1_ACCOUNT, REST_URL } from '@/constants';
import { TxPayloadCallFunction } from '@/types/aptosResources';
import { AptosClient, Types } from 'aptos';

export type TPool = {
  tokenX: string;
  tokenY: string;
  binStep: string;
};

const aptosClient = new AptosClient(REST_URL);

export const apiModules = {
  pool: {
    moduleName: 'pool',
    func: {
      existsAt: 'exists_at',
      isInitialized: 'is_initialized',
      getAmountOut: 'get_amount_out',
      getAmountIn: 'get_amount_in',
    },
  },
  scripts: {
    moduleName: 'entry',
    func: {
      swapExactXForY: 'swap_exact_x_for_y',
      swapExactYForX: 'swap_exact_y_for_x',
      swapXForExactY: 'swap_x_for_exact_y',
      swapYForExactX: 'swap_y_for_exact_x',
    },
  },
};

/**
 * Pool Module
 */

export const fetchExistsAt = async (x: string, y: string, binStep: string) => {
  const {
    moduleName,
    func: { existsAt: name },
  } = apiModules.pool;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, name);

  const payload = {
    function: func_id,
    type_arguments: [x, y, binStep] as Types.MoveType[],
    arguments: [],
  };

  try {
    const response = await aptosClient.view(payload);
    return response[0];
  } catch (err) {
    console.error(err);
  }
};

export const fetchIsInitialized = async () => {
  const {
    moduleName,
    func: { isInitialized: viewName },
  } = apiModules.pool;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, viewName);

  const payload = {
    function: func_id,
    type_arguments: [] as Types.MoveType[],
    arguments: [],
  };

  try {
    const response = await aptosClient.view(payload);
    return response[0];
  } catch (err) {
    console.error(err);
  }
};

/**
 * https://github.com/pontem-network/liquidswap_v1/blob/main/sources/pool.move
 * @param amountIn
 * @param swapYForX
 * @param pool
 * @returns
 */
export const simulateGetAmountOut = async (
  amountIn: number,
  swapYForX: boolean,
  pool: TPool,
) => {
  const { tokenX, tokenY, binStep } = pool;
  const {
    moduleName,
    func: { getAmountOut: viewName },
  } = apiModules.pool;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, viewName);

  const payload = {
    function: func_id,
    type_arguments: [tokenX, tokenY, binStep] as Types.MoveType[],
    arguments: ['' + amountIn, swapYForX],
  };

  try {
    const response = await aptosClient.view(payload);
    const [amountOut, leftoverOfAmountIn, fees] = response;
    return { amountOut, leftoverOfAmountIn, fees };
  } catch (err) {
    console.error(err);
  }
};

/**
 * https://github.com/pontem-network/liquidswap_v1/blob/main/sources/pool.move
 * @param amountOut
 * @param swapYForX
 * @param pool
 * @returns
 */
export const simulateGetAmountIn = async (
  amountOut: number,
  swapYForX: boolean,
  pool: TPool,
) => {
  const { tokenX, tokenY, binStep } = pool;
  const {
    moduleName,
    func: { getAmountIn: viewName },
  } = apiModules.pool;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, viewName);

  const payload = {
    function: func_id,
    type_arguments: [tokenX, tokenY, binStep] as Types.MoveType[],
    arguments: ['' + amountOut, swapYForX],
  };

  try {
    const response = await aptosClient.view(payload);
    const [amountIn, leftoverOfAmountOut, fees] = response;
    return { amountIn, leftoverOfAmountOut, fees };
  } catch (err) {
    console.error(err);
  }
};

/**
 * Script module
 */

/**
 * Payload for exact amount of token type `X` for token type `Y`.
 * ex: when there are changes in the input with the type of token X,
 *     we expect that we will spend the exact number of token X
 *     and get at least `yCoinsOutMinVal` of token Y
 * Src: https://github.com/pontem-network/liquidswap_v1/blob/main/router/sources/scripts.move
 *
 * @param amountX - Guaranteed amount of tokens of type `X` that will be debited from the account
 * @param amountYMinAllowableThreshold - The user will not receive the number of tokens `Y` less than the allowable threshold.
 *                                       The transaction will be rejected.
 * @param pool - pool's data - tokens must be sorted
 */
export const getPayloadExactXForY = (
  amountX: number,
  amountYMinAllowableThreshold: number,
  pool: TPool,
) => {
  const { tokenX, tokenY, binStep } = pool;
  const {
    moduleName,
    func: { swapExactXForY: viewName },
  } = apiModules.scripts;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, viewName);
  return {
    type: 'entry_function_payload',
    function: func_id,
    typeArguments: [tokenX, tokenY, binStep] as Types.MoveType[],
    arguments: ['' + amountX, amountYMinAllowableThreshold],
  } as TxPayloadCallFunction;
};

/**
 * Payload for token `X` for exact amount of token `Y`.
 * ex: it is necessary to get a guaranteed amount of token of type `Y`,
 *     without knowing how much it will be in tokens of type `X`
 *     at the time of swap.
 * Src: https://github.com/pontem-network/liquidswap_v1/blob/main/router/sources/scripts.move
 *
 * @param yCoinsRequiredOut - the expected guaranteed amount of token `Y` to receive
 * @param amountXMaxAllowableThreshold - the maximum allowable threshold for spending the token `X`
 * @param pool - pool's data - tokens must be sorted
 */
export const getPayloadXForExactY = (
  yCoinsRequiredOut: number,
  amountXMaxAllowableThreshold: number,
  pool: TPool,
) => {
  const { tokenX, tokenY, binStep } = pool;
  const {
    moduleName,
    func: { swapXForExactY: viewName },
  } = apiModules.scripts;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, viewName);

  return {
    type: 'entry_function_payload',
    function: func_id,
    typeArguments: [tokenX, tokenY, binStep] as Types.MoveType[],
    arguments: ['' + amountXMaxAllowableThreshold, yCoinsRequiredOut],
  } as TxPayloadCallFunction;
};

/**
 * Payload for token `Y` for exact amount of token of type `X`.
 * ex: it is necessary to get the exact amount of token of type `X`,
 *     spending no more than the allowable threshold of token of type `Y`
 * Src: https://github.com/pontem-network/liquidswap_v1/blob/main/router/sources/scripts.move

 * @param xCoinsRequiredOut - amount of token `X` that must be guaranteed to receive.
 * @param amountYMaxAllowedThreshold - the maximum allowable threshold for spending the token Y
 * @param pool - pool's data - tokens must be sorted
 */
export const getPayloadYForExactX = (
  xCoinsRequiredOut: number,
  amountYMaxAllowedThreshold: number,
  pool: TPool,
) => {
  const { tokenX, tokenY, binStep } = pool;
  const {
    moduleName,
    func: { swapYForExactX: viewName },
  } = apiModules.scripts;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, viewName);

  return {
    type: 'entry_function_payload',
    function: func_id,
    typeArguments: [tokenX, tokenY, binStep] as Types.MoveType[],
    arguments: ['' + amountYMaxAllowedThreshold, xCoinsRequiredOut],
  } as TxPayloadCallFunction;
};

/**
 * Payload for exact amount of coin `Y` for coin `X`.
 * Ex: it is necessary to get the number of tokens of type `X`
 *     not less than the minimum allowable threshold
 * Src: https://github.com/pontem-network/liquidswap_v1/blob/main/router/sources/scripts.move
 *
 * @param amountY - Guaranteed amount of tokens of type `Y` that will be debited from the account
 * @param amountXMinAllowableThreshold - The user will not receive the number of tokens `X` less than the allowable threshold.
 *                                       The transaction will be rejected.
 * @param pool
 */
export const getPayloadExactYForX = (
  amountY: number,
  amountXMinAllowableThreshold: number,
  pool: TPool,
) => {
  const { tokenX, tokenY, binStep } = pool;
  const {
    moduleName,
    func: { swapExactYForX: viewName },
  } = apiModules.scripts;

  const func_id = createFuncString(MODULES_V1_ACCOUNT, moduleName, viewName);

  return {
    type: 'entry_function_payload',
    function: func_id,
    typeArguments: [tokenX, tokenY, binStep] as Types.MoveType[],
    arguments: ['' + amountY, amountXMinAllowableThreshold],
  } as TxPayloadCallFunction;
};

/**
 * Auxiliary function that generates a string with the name of the called function for the view method
 * @param account
 * @param module
 * @param func
 * @returns
 */
export function createFuncString(
  account: string,
  module: string,
  func: string,
) {
  return [account, module, func].join('::');
}
