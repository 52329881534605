import { computed, reactive, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useStore } from '@/store/useStore';
import { useTokensStore } from '@/store/useTokensStore';
import { IStoredToken } from '@/types';
import {
  LiquidityDistributionType,
  LiquidityShapeType,
} from '@/types/liquidity';
import { is_sorted } from '@/utils/contracts';
import { d, decimalsMultiplier } from '@/utils';

export const useCurrencies = defineStore('currenciesStore', () => {
  const mainStore = useStore();
  const tokensStore = useTokensStore();

  const from = reactive<IStoredToken>({
    token: undefined,
    amount: undefined,
    reserve: 0,
  });

  const to = reactive<IStoredToken>({
    token: undefined,
    amount: undefined,
    reserve: 0,
  });

  const sortedTokenEntities = computed(() => {
    if (!from.token || !to.token) return [];
    return is_sorted(from.token, to.token) ? [from, to] : [to, from];
  });

  const sortedStoredTokenEntities = computed(() => {
    if (!from.token || !to.token) return [];
    const fromTokenEntity = tokensStore.getToken(from.token);
    const toTokenEntity = tokensStore.getToken(to.token);

    return is_sorted(from.token, to.token)
      ? [fromTokenEntity, toTokenEntity]
      : [toTokenEntity, fromTokenEntity];
  });

  const fromToken = computed(() => tokensStore.getToken(from.token));
  const toToken = computed(() => tokensStore.getToken(to.token));

  const fromAmountWithDecimals = computed(() => {
    if (!fromToken.value) {
      return;
    }

    return +d(from.amount)
      .mul(decimalsMultiplier(fromToken.value.decimals))
      .toFixed(fromToken.value.decimals);
  });

  const toAmountWithDecimals = computed(() => {
    if (!toToken.value) {
      return;
    }

    return +d(to.amount)
      .mul(decimalsMultiplier(toToken.value.decimals))
      .toFixed(toToken.value.decimals);
  });

  const isSorted = ref(false);
  const binStep = ref<number | null>(null);

  const activeShape = ref<LiquidityShapeType>('spot');
  const distributionType = ref<LiquidityDistributionType>('price-range');

  function resetState() {
    if (!from.token) {
      from.token = mainStore.defaultToken.value;
      from.amount = undefined;
      from.reserve = 0;
    }

    if (!to.token) {
      to.token = undefined;
      to.amount = undefined;
      to.reserve = 0;
    }
  }

  function updateReserves(
    coinXReserve: number,
    coinYReserve: number,
    newBinStep: number,
  ) {
    if (!from.token || !to.token) {
      return;
    }

    isSorted.value = is_sorted(from.token, to.token);

    from.reserve = isSorted.value ? coinXReserve : coinYReserve;
    to.reserve = isSorted.value ? coinYReserve : coinXReserve;
    binStep.value = newBinStep;
  }

  watch([() => tokensStore.tokens.value], () => {
    from.token =
      tokensStore.getToken(from.token)?.type || mainStore.defaultToken.value;
    to.token =
      from.token !== tokensStore.getToken(to.token)?.type
        ? tokensStore.getToken(to.token)?.type
        : undefined;
  });

  function resetShapeAndType() {
    distributionType.value = 'price-range';
    activeShape.value = 'spot';
  }

  function resetStore() {
    from.token = undefined;
    from.amount = undefined;
    from.reserve = 0;

    to.token = undefined;
    to.amount = undefined;
    to.reserve = 0;

    binStep.value = null;
  }

  return {
    from,
    to,
    fromAmountWithDecimals,
    toAmountWithDecimals,
    isSorted,
    binStep,
    activeShape,
    distributionType,
    sortedTokenEntities,
    sortedStoredTokenEntities,
    resetState,
    updateReserves,
    resetShapeAndType,
    resetStore,
  };
});
