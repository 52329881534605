export enum EMixpanelEventNames {
  ActiveUser = 'ls-active-user',
  StatClicked = 'ls-nav-stat-click',
  FarmClicked = 'ls-nav-farm-click',
  SoloClicked = 'ls-nav-solo-click',
  StakingClicked = 'ls-nav-staking-click',
  PoolsClicked = 'ls-nav-pools-click',
  LearnMoreClicked = 'ls-nav-learn-more-click',
  BridgeClicked = 'ls-nav-layerzero-bridge-click',
  OnboardingClicked = 'ls-nav-onboarding-click',
  FaucetClick = 'ls-nav-faucet-click',
  ConcentratedLiquidityClick = 'ls-nav-concentrated-liquidity',
  DashboardClicked = 'ls-nav-dashboard-click',

  //Swap flow
  SwapButtonClicked = 'ls-swap-button-click',
  SwapConfirmClicked = 'ls-swap-confirm-click',
  SwapSuccess = 'ls-swap-success',
  SwapKanaButtonClicked = 'ls-kana-swap-click',

  //Add liquidity
  AddLiquidityButtonClicked = 'ls-add-liquidity-button-click',
  AddLiquidityConfirmClicked = 'ls-add-liquidity-confirm-click',
  AddLiquiditySuccess = 'ls-add-liquidity-success',

  //Remove liquidity flow
  RemoveLiquidityButtonClicked = 'ls-remove-liquidity-button-click',
  RemoveLiquidityConfirmClicked = 'ls-remove-liquidity-confirm-click',
  RemoveLiquiditySuccess = 'ls-remove-liquidity-success',

  //Create pool flow
  CreatePoolButtonClicked = 'ls-create-pool-button-click',
  CreatePoolConfirmClicked = 'ls-create-pool-confirm-click',
  CreatePoolSuccess = 'ls-create-pool-success',

  //Buy apt
  BuyAptClicked = 'ls-buy-apt-click',
  StartMoonpay = 'ls-start-moonpay-flow',
  StartTransak = 'ls-start-transak-flow',
  StartCoinbase = 'ls-start-coinbase-flow',
  FinishMoonpay = 'ls-finish-moonpay-flow',
  FinishTransak = 'ls-finish-transak-flow',
  FinishCoinbase = 'ls-finish-coinbase-flow',

  //CL
  PoolsSelectV1Clicked = 'ls-all-pools-select-v1-clicked',
  PoolsSelectV0Clicked = 'ls-all-pools-select-v0-clicked',
  PoolsV1SwapClicked = 'ls-all-pools-swap-clicked',
  PoolsV1AddLiquidityClicked = 'ls-all-pools-v1-add-liquidity-clicked',
  PoolsV1AddLiquidityViaRowClicked = 'ls-all-pools-v1-add-liquidity-row-clicked',
  MyPoolsV1SwapClicked = 'ls-my-pools-swap-clicked',
  MyPoolsV1AddLiquidityClicked = 'ls-my-pools-v1-add-liquidity-clicked',
  MyPoolsV1RemoveLiquidityClicked = 'ls-my-pools-v1-remove-liquidity-clicked',
  MyPoolsV1AddLiquidityViaRowClicked = 'ls-my-pools-v1-add-liquidity-row-clicked',

  //CL mobile
  PoolsV1MobileSwapClicked = 'ls-all-pool-v1-mobile-swap-clicked',
  PoolsV1MobileAddLiquidityClicked = 'ls-all-pools-v1-mobile-add-liquidity-clicked',
  PoolsV1MobileRemoveLiquidityClicked = 'ls-all-pools-v1-mobile-remove-liquidity-clicked',

  //Dashboard
  DashboardSaveImage = 'ls-dashboard-save-image',
  DashboardShareOnTwitter = 'ls-dashboard-share-on-twitter',

  //Banners
  BannerGoAirdrop = 'banner-go-to-airdrop',
  BannerGoToFarms = 'banner-go-to-farm',
  BannerGoToPoolDooDoo = 'banner-go-to-pool-doodoo',
  BannerDashboard = 'banner-dashboard',
  BannerDumdum = 'banner-dumdum',
  BannerUptos = 'banner-uptos',
  BannerReturd = 'banner-returd',
  WalletBannerMain = 'wallet-banner-main',
  ShrimpMain = 'shrimp-main',
  DumdumMain = 'dumdum-main',
  UptosMain = 'uptos-main',
  LSDAptos = 'lsd-aptos-banner',
  LSDUSDC = 'lsd-usdc-banner',
  LSDDoodoo = 'lsd-doodoo-banner',
}

export enum EMixpanelProvideKeys {
  Mixpanel = '$mixpanel',
  Track = '$track',
  TrackLinks = '$trackLinks',
}
