import { getPoolLpStr, getPoolStr } from '@/utils/pools';
import { defineStore } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from '@/store';
import { tryOnMounted, watchDebounced } from '@vueuse/core';
import { useAptosClient } from '@/composables/useAptosClient';
import {
  calcOutputBurnLiquidity,
  composeType,
  getCurve,
  getModulesAccount,
  getResourcesAccount,
  getScriptsFor,
  is_sorted,
  lpTokenNameStr,
  withSlippage,
} from '@/utils/contracts';
import { getFromCache } from '@/utils/cache';
import { useSlippage } from '@/composables/useSlippage';
import { usePoolExistence } from '@/composables/usePoolExistence';
import { IStoredToken } from '@/types';
import { moduleAddress } from '@/utils/networkData';
import { useTokensStore } from './useTokensStore';
import { useContractVersion, Version } from '@/composables/useContractVersion';
import { d } from '@/utils';
import { CURVE_STABLE, CURVE_STABLE_V05 } from '@/constants/networks';
import {
  ChainsEnum,
  CORRECT_CHAIN,
  MODULES_V1_ACCOUNT,
  VERSION_0,
  VERSION_0_5,
  VERSION_SOLO,
} from '@/constants';
import { useCurrentAccountBalance } from '@/composables/useAccountBalance';
import { hasLpBalance } from '@/utils/balance';
import {
  GetMostLiquidityPoolOptions,
  useMostLiquidityPool,
} from '@/composables/mostLiquidityPool';
import { IPersistedPoolSolo } from '@/types/pools';
import { useAptosV2Client } from '@/composables/useAptosV2Client';
import { parseTypeTag } from '@aptos-labs/ts-sdk';

export const useBurnLiquidityStore = defineStore('burnLiquidityStore', () => {
  const { version, setVersion: setContractVersion } = useContractVersion();
  const from = reactive<Omit<IStoredToken, 'decimals'>>({
    token: undefined,
    reserve: 0,
  });
  const to = reactive<Omit<IStoredToken, 'decimals'>>({
    token: undefined,
    reserve: 0,
  });
  const isL2 = computed(() => CORRECT_CHAIN.id === ChainsEnum['lumio-testnet']);
  const pool = ref<IPersistedPoolSolo | null>(null);

  const curve = ref<string>(
    version.value === VERSION_SOLO ? '' : getCurve('unstable', version.value),
  );
  const poolExistence = usePoolExistence();

  const burnAmount = ref<number | undefined>();
  const shouldFindOptimalPool = ref(true);

  const isUpdatingRate = ref<boolean>(false);
  const isSorted = ref<boolean>(false);
  const lpSupply = ref<number>(0);
  const soloExpectedOutput = reactive({
    value: 0,
  });

  const slippage = useSlippage();

  const tokensStore = useTokensStore();
  const mainStore = useStore();
  const { simulateTransaction } = useAptosV2Client();
  const aptos = useAptosClient();

  const mostLiquidityPoolService = useMostLiquidityPool();

  async function refetchSoloExpectedOutput() {
    if (Number(burnAmount.value?.toFixed(0)) === 0) {
      soloExpectedOutput.value = 0;
      return;
    }
    try {
      const moduleAddress = getModulesAccount(version.value);
      const scriptsVersion = getScriptsFor(version.value);
      const functionName = composeType(
        moduleAddress,
        scriptsVersion,
        'withdraw',
      );

      const isSortedForSolo = is_sorted(from.token ?? '', to.token ?? '');

      const soloPool = pool.value;

      const args = [soloPool?.vault, Number(burnAmount.value?.toFixed(0))];

      const typeArguments = isSortedForSolo
        ? [
            from.token,
            to.token,
            composeType(
              MODULES_V1_ACCOUNT,
              'bin_steps',
              `X${soloPool?.binStep}`,
            ),
          ]
        : [
            to.token,
            from.token,
            composeType(
              MODULES_V1_ACCOUNT,
              'bin_steps',
              `X${soloPool?.binStep}`,
            ),
          ];

      const simRes = await simulateTransaction({
        function: functionName,
        functionArguments: args,
        typeArguments: typeArguments.map((arg) => parseTypeTag(arg ?? '')),
      });

      const foundEvent = simRes.events.find((event) =>
        event.type.includes('::vault::WithdrawEvent'),
      );

      if (!foundEvent) {
        soloExpectedOutput.value = 0;

        return;
      }

      const xOut = +foundEvent.data.coin_x_val;
      const yOut = +foundEvent.data.coin_y_val;

      soloExpectedOutput.value = xOut || yOut;
    } catch (e) {
      soloExpectedOutput.value = 0;
    }
  }

  const lpTokenName = computed(() => {
    if (version.value === VERSION_SOLO) return 'LP';
    return lpTokenNameStr(
      {
        tokens: [from.token, to.token],
        curve: curve.value,
      },
      version.value,
    );
  });
  const lpToken = ref<any>();
  const lpBalance = ref<any>();

  lpBalance.value = useCurrentAccountBalance(
    computed(() => lpTokenName.value),
    { useSuffix: false, _decimals: 6 },
  );

  const output = computed(() => {
    if (!from.reserve || !to.reserve || !lpSupply.value || !burnAmount.value) {
      return;
    }

    const outputVal = calcOutputBurnLiquidity({
      xReserve: from.reserve,
      yReserve: to.reserve,
      lpSupply: lpSupply.value,
      toBurn: burnAmount.value,
    });

    if (!outputVal) {
      return;
    }

    return {
      x: withSlippage(slippage.value.value, outputVal['x']),
      y: withSlippage(slippage.value.value, outputVal['y']),
      withoutSlippage: { x: outputVal['x'], y: outputVal['y'] },
    };
  });

  watch([tokensStore.tokens], () => {
    from.token =
      tokensStore.getToken(from.token)?.type || mainStore.defaultToken.value;
    to.token =
      from.token !== tokensStore.getToken(to.token)?.type
        ? tokensStore.getToken(to.token)?.type
        : undefined;
  });

  tryOnMounted(() => resetState());

  function resetState() {
    setContractVersion(isL2.value ? VERSION_0_5 : version.value);
    from.token = mainStore.defaultToken.value;
    to.token = undefined;
    if (version.value !== VERSION_SOLO) {
      curve.value = getCurve('unstable', version.value);
    }
    from.reserve = 0;
    to.reserve = 0;
  }

  async function refetchRates(silent = false) {
    if (from.token && to.token && version.value !== VERSION_SOLO) {
      if (!silent) {
        isUpdatingRate.value = true;
      }

      isSorted.value = is_sorted(from.token, to.token);
      const [fromToken, toToken] = isSorted.value
        ? [from.token, to.token]
        : [to.token, from.token];

      const resourceType = getPoolStr(
        fromToken,
        toToken,
        curve.value,
        version.value,
      );

      const lp = getPoolLpStr(fromToken, toToken, curve.value, version.value);
      const resourceAccount = getResourcesAccount(version.value);
      const response = await getFromCache(
        ['calc', resourceAccount, resourceType].join('-'),
        () => aptos.client.getAccountResource(resourceAccount, resourceType),
        { time: 2000 },
      );

      if (!response) {
        from.reserve = 0;
        to.reserve = 0;
        isUpdatingRate.value = false;
        return;
      } else {
        const coinXReserve = +response.data.coin_x_reserve.value;
        const coinYReserve = +response.data.coin_y_reserve.value;

        from.reserve = isSorted.value ? coinXReserve : coinYReserve;
        to.reserve = isSorted.value ? coinYReserve : coinXReserve;
      }

      const lpSupplyResponse = await getFromCache(
        ['coin-info', lp].join('-'),
        fetchLpSupply,
        {
          time: 2000,
          args: [resourceAccount, lp],
        },
      );

      lpSupply.value =
        lpSupplyResponse?.data?.supply?.vec[0]?.integer?.vec[0]?.value;

      if (!silent) {
        isUpdatingRate.value = false;
      }
    }
  }

  async function check() {
    if (!from?.token || !to?.token || version.value === VERSION_SOLO) return;
    await poolExistence.check(
      {
        fromCoin: from.token,
        toCoin: to.token,
        curve: curve.value,
      },
      version.value,
    );
  }

  // For correct behavior of Curve switch after Version was switched
  watch(version, (newVersion, _) => {
    if (newVersion === VERSION_SOLO) return;
    const wasStable = [CURVE_STABLE, CURVE_STABLE_V05].includes(curve.value);
    curve.value = getCurve(wasStable ? 'stable' : 'unstable', newVersion);
  });

  watch(
    () => lpTokenName.value,
    async () => {
      lpToken.value = await tokensStore.getTokenInfo(lpTokenName.value);
    },
    { immediate: true },
  );

  watchDebounced(
    () => [from, to, curve, version],
    async () => {
      await check();
      refetchRates(false);
    },
    {
      debounce: 500,
      maxWait: undefined,
      deep: true,
    },
  );

  const tokensChosen = computed(() => !!from.token && !!to.token);

  watch(
    () => [from.token, to.token],
    async () => {
      if (
        !shouldFindOptimalPool.value ||
        !from.token ||
        !to.token ||
        version.value === VERSION_SOLO
      ) {
        return;
      }

      const hasStableV0LpBalance = await hasLpBalance(
        from.token,
        to.token,
        'stable',
        VERSION_0,
      );
      const hasUnstableV0LpBalance = await hasLpBalance(
        from.token,
        to.token,
        'unstable',
        VERSION_0,
      );
      const hasStableV05LpBalance = await hasLpBalance(
        from.token,
        to.token,
        'stable',
        VERSION_0_5,
      );
      const hasUnstableV05LpBalance = await hasLpBalance(
        from.token,
        to.token,
        'unstable',
        VERSION_0_5,
      );

      const hasStableCurve = hasStableV0LpBalance || hasStableV05LpBalance;
      const hasUnstableCurve =
        hasUnstableV0LpBalance || hasUnstableV05LpBalance;
      const hasV0 =
        !isL2.value && (hasStableV0LpBalance || hasUnstableV0LpBalance);
      const hasV05 = hasStableV05LpBalance || hasUnstableV05LpBalance;

      if ((!hasStableCurve && !hasUnstableCurve) || (!hasV0 && !hasV05)) {
        return;
      }

      const poolCombinations = [
        hasStableV0LpBalance && { type: 'stable', version: VERSION_0 },
        hasStableV05LpBalance && { type: 'stable', version: VERSION_0_5 },
        hasUnstableV0LpBalance && { type: 'unstable', version: VERSION_0 },
        hasUnstableV05LpBalance && { type: 'unstable', version: VERSION_0_5 },
      ].filter(Boolean) as GetMostLiquidityPoolOptions['poolCombinations'];

      const resource = await mostLiquidityPoolService.getMostLiquidityPool({
        tokenX: from.token,
        tokenY: to.token,
        poolCombinations,
      });

      if (!resource) {
        return;
      }

      curve.value = resource.curve;
      version.value = Number(resource.version) as Version;
    },
  );

  const isPoolAbsence = computed(
    () =>
      tokensChosen.value &&
      version.value !== VERSION_SOLO &&
      // from.reserve === 0 &&
      // to.reserve === 0 &&
      !poolExistence.isFetching(
        {
          fromCoin: from.token ?? '',
          toCoin: to.token ?? '',
          curve: curve.value,
        },
        version.value,
      ) &&
      !poolExistence.poolExists(
        {
          fromCoin: from.token ?? '',
          toCoin: to.token ?? '',
          curve: curve.value,
        },
        version.value,
      ),
  );

  const isBusy = computed(
    () => poolExistence.isFetching || isUpdatingRate.value,
  );

  const calcReward = (
    pooledFunds: number,
    maxLPTokenBalance: number,
    amountOfTokenAfterBurn: number,
    desiredLPTokenAmountForBurn: number,
  ) => {
    return d(pooledFunds)
      .mul(d(desiredLPTokenAmountForBurn).div(maxLPTokenBalance))
      .minus(d(amountOfTokenAfterBurn));
  };

  async function fetchLpSupply(
    resourceAccountAddress: string,
    lpTokenType: string,
  ) {
    return await aptos.client.getAccountResource(
      resourceAccountAddress,
      composeType(moduleAddress('CoinInfo'), [lpTokenType]),
    );
  }

  return {
    version,
    burnAmount,
    lpToken,
    lpBalance,
    pool,

    isBusy,
    isPoolAbsence,
    shouldFindOptimalPool,
    curve,
    fromCurrency: from,
    toCurrency: to,
    isUpdatingRate,
    slippage: ref(slippage),
    isSorted,
    output,
    refetchRates,
    lpTokenName,
    tokensChosen,
    poolExists: poolExistence.poolExists,
    calcReward,
    fetchLpSupply,
    refetchSoloExpectedOutput,
    soloExpectedOutput,
  };
});
