import gql from 'graphql-tag';

export const GetAllNftsByCreatorAccount = gql`
  query GetAccountNftListByCreatorAccount(
    $creatorAddress: String
    $limit: Int
    $offset: Int
  ) {
    current_token_ownerships(
      where: { amount: { _gt: "0" }, creator_address: { _eq: $creatorAddress } }
      limit: $limit
      offset: $offset
      order_by: { name: asc }
    ) {
      amount
      collection_data_id_hash
      collection_name
      creator_address
      current_token_data {
        collection_data_id_hash
        collection_name
        creator_address
        default_properties
        current_collection_data {
          collection_data_id_hash
          collection_name
          creator_address
          description
          description_mutable
          last_transaction_timestamp
          last_transaction_version
          maximum
          maximum_mutable
          metadata_uri
          supply
          table_handle
          uri_mutable
        }
        description
        description_mutable
        last_transaction_timestamp
        largest_property_version
        last_transaction_version
        maximum
        maximum_mutable
        metadata_uri
        name
        payee_address
        properties_mutable
        royalty_mutable
        royalty_points_denominator
        supply
        royalty_points_numerator
        token_data_id_hash
        uri_mutable
      }
      current_collection_data {
        collection_data_id_hash
        collection_name
        creator_address
        description
        description_mutable
        maximum
        last_transaction_version
        last_transaction_timestamp
        maximum_mutable
        metadata_uri
        supply
        table_handle
        uri_mutable
      }
      last_transaction_timestamp
      last_transaction_version
      name
      owner_address
      property_version
      table_type
      token_data_id_hash
      token_properties
    }
  }
`;
