import {
  BUILD_CHAIN_ID,
  RESOURCES_ACCOUNT,
  RESOURCES_V05_ACCOUNT,
  VERSION_0,
  VERSION_0_5,
} from '@/constants';
import {
  calcOutputBurnLiquidity,
  getContractVersionFromCurve,
  getCurve,
  getResourcesAccount,
  getShortCurveFromFull,
  is_sorted,
  isComplexCurve,
} from '@/utils/contracts';
import { IPersistedPool, IPoolBase } from '@/types/pools';
import { IStorageBasic } from '@/types';
import {
  computed,
  inject,
  onBeforeMount,
  reactive,
  ref,
  unref,
  watch,
} from 'vue';
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import {
  destructCoinStorePoolStr,
  getPoolLpInfoStr,
  getPoolLpStr,
  getPoolStr,
  getTitleForPool,
} from '@/utils/pools';
import { useAptosClient } from '@/composables/useAptosClient';
import { useStore } from './useStore';
import { useNFTStore } from './useNFTStore';
import { getFromCache } from '@/utils/cache';
import { getTokenPrice, getUSDEquivalent } from '@/composables/useCoinPrice';
import { useTokensStore } from './useTokensStore';
import { fetchAllPooledFundsByAddress } from '@/service/api';
import { captureException } from '@sentry/vue';
import { usePoolsV1Store } from './usePoolsV1Store';
import { d } from '@/utils';
import RestModule, { GetPools } from 'ls-api';
import { useRoute } from 'vue-router';

type Pool = GetPools.Response[number];

interface IStorage extends IStorageBasic {
  pools: IPersistedPool[];
}

type LPCoin = {
  type: string;
  value: string;
  coinX: string;
  coinY: string;
  curve: string;
};

export const usePoolsStore = defineStore('poolsStore', () => {
  const mainStore = useStore();
  const nftStore = useNFTStore();
  const aptos = useAptosClient();
  const poolsV1Store = usePoolsV1Store();
  const isLoading = ref(false);
  const poolsMap = reactive<Record<string, IPersistedPool>>({});
  const loaders = reactive({ addedFundsLoader: false, addedAccountLps: false });
  const $http = inject('$http') as RestModule['controller'];
  const isLoadMyPools = ref(false);
  const route = useRoute();
  const loadByOnePools = ref(0);
  /**
   * To prevent a large number of transformations on the client.
   * We use a map where the keys have the form:
   * [token X alias]/[token Y alias]-[short-curve]-[version] (ex. whUSDC-APT-uncorrelated-0)
   * This map is generated when the pool is registered. Pools for a specific account are:
   * 1. pools from coins-registry
   * 2. pools to which liquidity has been added
   */
  const poolsTitleMap = reactive<Record<string, string>>({});
  const isReady = ref(false);
  /**
   * Data structure with <string> generic
   * as sorted `${TokenA}-${TokenB}` types
   */
  const predefinedCurves = {
    stable: new Set<string>(),
    unstable: new Set<string>(),
  };

  const lpCoins = ref<LPCoin[]>([]);
  /**
   * To store added reserves
   */
  const poolsStorage = useStorage<IStorage>(
    'pontemPools',
    { pools: [], version: 1 },
    window.localStorage,
    { writeDefaults: true },
  );
  // TODO: Move to staking store
  const stakingLpDontShow = useStorage(
    'staking-modal',
    false,
    window.localStorage,
    { writeDefaults: true },
  );

  /**
   * Storage for all loaded pools
   */
  const pools = ref<IPersistedPool[]>([]);

  async function fetchAccountLps() {
    if (!mainStore.account.value?.address) return;
    loaders.addedAccountLps = true;

    const { networkId } = mainStore;
    const resources = await aptos.client.getAccountResources(
      mainStore.account.value.address,
    );

    resources.forEach((element: any) => {
      if (
        element.type.indexOf('::stake::') !== -1 ||
        element.type.indexOf('lp_coin') === -1
      )
        return;
      const [coinX, coinY, curveType] = destructCoinStorePoolStr(element.type);

      let lpVersion;
      try {
        lpVersion = getContractVersionFromCurve(curveType);
      } catch (e) {
        /**
         * There is a pool with an unknown curve on the tester's account:
         * - address: 0x09cf4723f4bd23a841e7a5bcdccc6e7fb35a76784e8c041a515f6368b17ebcbf
         * - unknown curve ex.: 0x4e9fce03284c0ce0b86c88dd5a46f050cad2f4f33c4cdd29d98f501868558c81::curves::Uncorrelated
         * When an exception is thrown, the forEach loop stops working.
         * To continue execution, it is necessary to return the result of the function.
         */
        console.error(e);
        return;
      }

      const poolStr = getPoolStr(coinX, coinY, curveType, lpVersion);
      const pool = poolsMap[poolStr];
      const lpValue = +element.data.coin.value;
      if (!pool) {
        registerPool(
          {
            coinX,
            coinY,
            curve: curveType,
            networkId: unref(networkId),
            contract: lpVersion,
            exceptional: false,
          },
          {},
        )
          .then(() => {
            const pool = poolsMap[poolStr];
            if (!pool) return;
            pool.lp = lpValue;
          })
          .catch((e) => {
            captureException(e);
          });
        return;
      }
      pool.lp = lpValue;
    });
    loaders.addedAccountLps = false;
  }

  async function fetchPoolsList(
    only?: Array<{
      coinX: string;
      coinY: string;
      curve: string;
      networkId: number;
      contract: number;
    }>,
  ) {
    let pools = await $http.getRegisteredPools({ networkId: BUILD_CHAIN_ID });

    if (!pools) {
      return;
    }

    if (only) {
      pools = pools.filter((pool) =>
        only.some(({ coinX, coinY, curve, networkId, contract }) => {
          return (
            pool.coinX.type === coinX &&
            pool.coinY.type === coinY &&
            pool.curve === getShortCurveFromFull(curve) &&
            pool.networkId === networkId &&
            pool.version === contract
          );
        }),
      );

      loadByOnePools.value += pools.length;
    }

    registerPools(pools);
  }

  onBeforeMount(() => loadFromLocalStorage());

  /**
   * Load pools with added reserves from localStorage
   *
   * @returns
   */
  async function loadFromLocalStorage() {
    if (poolsStorage.value) {
      try {
        const { pools } = poolsStorage.value;
        if (!pools) return;

        for (const pool of pools) {
          // load only coins which can be persisted
          if (pool.addedX > 0 || pool.addedY > 0) {
            await loadPool(pool);
          }
        }
        isReady.value = true;
      } catch (_e) {
        //
      }
    }
  }

  /**
   * Persist pools list with added reserves only
   *
   * @param _pools
   * @param canClean
   * @returns
   */
  function persistToStorage(
    _pools: Record<string, IPersistedPool>,
    canClean?: boolean,
  ) {
    const persistingKeys = Object.keys(_pools).filter((key) => {
      return _pools[key].addedX > 0 || _pools[key].addedY > 0;
    });
    if (!canClean && persistingKeys.length == 0) return;
    let { version } = poolsStorage.value;
    // TODO: make an update logic while stored coins structure will be updated
    version = version ?? '1';
    poolsStorage.value = {
      version,
      pools: persistingKeys.map((key) => _pools[key]),
    };
  }

  watch(poolsMap, (_poolsMap) => persistToStorage(_poolsMap));

  watch(
    () => route.fullPath,
    async (_new, old) => {
      if (old.includes('pool') || !_new.includes('pool')) return;

      const checkLoadPools = () => {
        if (
          !Object.keys(poolsMap).length ||
          Object.keys(poolsMap).length - loadByOnePools.value <= 0
        ) {
          fetchDefaultPoolsData();
        }
      };

      await Promise.all([
        nftStore.getNFTHistory(),
        fetchAccountLps(),
        poolsV1Store.fetchPools(),

        checkLoadPools(),
      ]);
      setShareOfPoolForAllPools();
      return;
    },
  );

  /**
   * Watcher manages the updating of pool data depending on the address change
   */
  watch(
    () => mainStore.account.value,
    async (_new, old) => {
      const [newAddress, oldAddress] = [_new?.address, old?.address];

      // reset data at each change
      resetPoolsData();

      // states
      const isDisconnected = newAddress === undefined;
      const isAddressChanged =
        oldAddress !== undefined &&
        newAddress !== undefined &&
        newAddress !== oldAddress;
      const isNewConnected =
        oldAddress === undefined && newAddress !== undefined;
      const needToReUpdatePoolsData = oldAddress === newAddress;

      if (isDisconnected || !window.location.pathname.includes('pool')) {
        return;
      }
      const checkLoadPools = () => {
        if (!Object.keys(poolsMap).length) {
          fetchDefaultPoolsData();
        }
      };

      if (isAddressChanged || isNewConnected || needToReUpdatePoolsData) {
        await Promise.all([
          nftStore.getNFTHistory(),
          fetchAccountLps(),
          poolsV1Store.fetchPools(),

          // TODO remove after testing LS-1907
          // fetchAndSetPooledFundsForAllPools(newAddress),
          checkLoadPools(),
        ]);
        setShareOfPoolForAllPools();
        return;
      }
    },
  );

  async function fetchAndSetPooledFundsForAllPools(address: string) {
    const cacheKey = `all-deposit-${address}`;
    loaders.addedFundsLoader = true;
    await getFromCache(cacheKey, fetchAllPooledFundsByAddress, {
      time: 30000,
      args: [address],
    })
      .then((poolsDepositData) => {
        if (!poolsDepositData) {
          // throw Error('PoolsStore: There are no deposits in pools');
          return;
        }

        Object.values(poolsMap).forEach(async (pool) => {
          if (!pool || !mainStore?.account?.value?.address || !pool.lp) return;

          const tokenX = tokensStore.getToken(pool?.coinX);
          const tokenY = tokensStore.getToken(pool?.coinY);

          if (!tokenX || !tokenY) return;

          const currencyXPrice = await getTokenPrice(tokenX?.symbol);
          const currencyYPrice = await getTokenPrice(tokenY?.symbol);

          const balance = calcOutputBurnLiquidity({
            xReserve: pool.reserveX,
            yReserve: pool.reserveY,
            lpSupply: pool.lpTotal as number,
            toBurn: pool.lp as number,
          });

          const formatBalanceX = d(balance?.x)
            .dividedBy(d(10).pow(tokenX?.decimals as number))
            .toNumber();

          const formatBalanceY = d(balance?.y)
            .dividedBy(d(10).pow(tokenY?.decimals as number))
            .toNumber();

          const usdX = getUSDEquivalent(formatBalanceX, currencyXPrice);
          const usdY = getUSDEquivalent(formatBalanceY, currencyYPrice);

          pool.totalUsd = (usdX || 0) + (usdY || 0);

          pool.addedX = balance?.x || 0;
          pool.addedY = balance?.y || 0;
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaders.addedFundsLoader = false;
      });
  }

  async function fetchReserves(liquidityPool: string, contract?: number) {
    const pool: IPersistedPool = poolsMap[liquidityPool];
    const resourceAccount = getResourcesAccount(contract);
    const response = await aptos.client.getAccountResource(
      resourceAccount,
      liquidityPool,
    );

    if (!response) return;

    pool.reserveX = +response.data.coin_x_reserve.value;
    pool.reserveY = +response.data.coin_y_reserve.value;
  }

  async function fetchLp(lpCoinInfo: string, liquidityPool: string) {
    try {
      const pool: IPersistedPool = poolsMap[liquidityPool];
      if (!mainStore.account.value?.address) return;
      const response = await aptos.client.getAccountResource(
        mainStore.account.value.address,
        lpCoinInfo,
      );

      if (!response || !response?.data?.supply?.vec[0]) return;

      pool.lp = +response.data.supply.vec[0].integer.vec[0].value;
    } catch (_e) {
      // mute error if there is no lp token found
    }
  }

  function setShareOfPool(pool: IPersistedPool) {
    const percentage =
      pool.lpTotal && pool.lp ? (pool.lp / pool.lpTotal) * 100 : undefined;

    pool.shareOfPool = percentage;
  }

  function setShareOfPoolForAllPools() {
    Object.values(poolsMap).forEach((pool) => setShareOfPool(pool));
  }

  function resetPoolsData() {
    Object.values(poolsMap).forEach((p) => {
      p.lp = 0;
      p.addedX = 0;
      p.addedY = 0;
    });
  }

  const tokensStore = useTokensStore();

  async function fetchTotalLp(liquidityPool: string) {
    const pool: IPersistedPool = poolsMap[liquidityPool];
    try {
      const { coinX, coinY, curve, contract } = pool;
      const curveType =
        curve === 'stable' || curve === getCurve('stable', contract)
          ? getCurve('stable', contract)
          : getCurve('unstable', contract);

      const lp = getPoolLpStr(coinX, coinY, curveType, contract);
      const lpCoinInfo = getPoolLpInfoStr(lp);
      const resourceAccountByVersion =
        contract === VERSION_0_5 ? RESOURCES_V05_ACCOUNT : RESOURCES_ACCOUNT;

      const response = await getFromCache(
        ['coin-info', lp].join('-'),
        async () =>
          await aptos.client.getAccountResource(
            resourceAccountByVersion,
            lpCoinInfo,
          ),
        { time: 2000 },
      );
      if (!response || !response?.data?.supply?.vec[0]) return;
      pool.lpTotal = +response.data.supply.vec[0].integer.vec[0].value;
    } catch (_e) {
      // mute error if there is no lp token found
    }
  }

  async function loadPool(pool: IPersistedPool): Promise<IPersistedPool> {
    const { coinX, coinY, curve, contract } = pool; // curve is full-type

    const liquidityPool = getPoolStr(coinX, coinY, curve, contract);
    const lpCoinInfo = getPoolLpInfoStr(
      getPoolLpStr(coinX, coinY, curve, contract),
    );

    await Promise.all([
      fetchReserves(liquidityPool, contract),
      fetchLp(lpCoinInfo, liquidityPool),
      fetchTotalLp(liquidityPool),
    ]);

    // set additional info
    Object.values(poolsMap).forEach((pool) => {
      setShareOfPool(pool);
    });

    return pool;
  }

  function loadPoolByType(liquidityPool: string) {
    const pool = poolsMap[liquidityPool];
    if (!pool) throw new Error('Trying to load unregistered pool');
    return loadPool(pool);
  }

  async function registerPool(
    pool: IPoolBase,
    {
      rewrite = false,
      isDefault = false,
      lazy = true,
      checkPoolExistance = true,
      isSaveInPool = true,
    },
  ) {
    const {
      coinX,
      coinY,
      curve,
      networkId,
      contract,
      exceptional,
      tvl,
      apr,
      volume24,
    } = pool;

    if (
      contract === VERSION_0 &&
      (tokensStore.getToken(coinX)?.isFungibleAsset ||
        tokensStore.getToken(coinY)?.isFungibleAsset)
    ) {
      return;
    }

    const _coinX = coinX.replace(/^0x0+/, '0x');
    const _coinY = coinY.replace(/^0x0+/, '0x');

    const isSorted = is_sorted(_coinX, _coinY);
    const [sortedX, sortedY] = isSorted ? [_coinX, _coinY] : [_coinY, _coinX];

    /**
     * Both pools from the registry with unstable
     * and stable and pools with the full curve type can come here
     */
    const curveFullType = ['stable', 'unstable'].includes(curve)
      ? curve === 'stable'
        ? getCurve('stable', contract)
        : getCurve('unstable', contract)
      : curve;
    const liquidityPool = getPoolStr(sortedX, sortedY, curveFullType, contract);

    let isPoolExist = false;

    if (checkPoolExistance) {
      try {
        const resourceAccount = getResourcesAccount(contract);
        isPoolExist = await isPoolExistsInResource(
          resourceAccount,
          liquidityPool,
        );
      } catch (e) {
        console.error('Error loading the pool:', e);
      }
    } else {
      isPoolExist = true; // only for pools from coinsRegistry
    }
    /**
     * If the pool does not exist in the resource account,
     * then we don't need to register it.
     */
    if (!isPoolExist) {
      return undefined;
    }

    if (!rewrite && poolsMap[liquidityPool]) {
      return poolsMap[liquidityPool];
    }

    if (isDefault && ['stable', 'unstable'].includes(curve)) {
      predefinedCurves[curve as 'stable' | 'unstable'].add(
        `${sortedX}-${sortedY}-${contract}`,
      );
    }

    // title is used in sorting on the statistics page
    const title = getTitleForPool(sortedX, sortedY);

    const tokenEntities = {
      fromTokenEntity:
        pool.tokenEntities?.fromTokenEntity || tokensStore.getToken(sortedX),
      toTokenEntity:
        pool.tokenEntities?.toTokenEntity || tokensStore.getToken(sortedY),
    };

    if (!tokenEntities.fromTokenEntity) {
      tokenEntities.fromTokenEntity = await tokensStore.searchToken(sortedX);
    }

    if (!tokenEntities.toTokenEntity) {
      tokenEntities.toTokenEntity = await tokensStore.searchToken(sortedY);
    }

    const persistedPool: IPersistedPool = reactive({
      title,
      coinX: sortedX,
      coinY: sortedY,
      curve: curveFullType,
      id: `${sortedX}-${sortedY}-${curveFullType}`,
      reserveX: 0,
      reserveY: 0,
      addedX: 0,
      addedY: 0,
      lp: 0,
      networkId,
      isDefault,
      contract,
      exceptional: !!exceptional,
      totalUsd: 0,
      loaders,
      tvl,
      apr,
      volume24USD: volume24,
      tokenEntities: tokenEntities,
    });

    if (!isSaveInPool) {
      return persistedPool;
    }

    poolsMap[liquidityPool] = persistedPool;

    const shortCurveName = isComplexCurve(curve)
      ? getShortCurveFromFull(curve)
      : curve;

    const poolTitleKey = `${title}-${shortCurveName}-${contract}`;
    poolsTitleMap[poolTitleKey] = liquidityPool;

    if (lazy) {
      loadPool(persistedPool);
    } else {
      await loadPool(persistedPool);
    }
    return persistedPool;
  }

  /**
   * Load pools list in memory to get data about pools
   *
   * @param list
   */
  function registerPools(list: Pool[]): void {
    if (!list || !Array.isArray(list)) return;
    const registerPoolOptions = {
      rewrite: true,
      isDefault: true,
      checkPoolExistance: false,
    };

    // pools has 'stable' and 'unstable' curve from coin-registry
    list.map((poolInfo: Pool) => {
      const {
        coinX,
        coinY,
        curve,
        version,
        networkId,
        exceptional = false,
        tvl,
        apr,
        volume24,
      } = poolInfo;

      registerPool(
        {
          coinX: coinX.type.replace(/^0x0+/, '0x'),
          coinY: coinY.type.replace(/^0x0+/, '0x'),
          curve,
          contract: Number(version),
          networkId,
          exceptional,
          tvl: Number(tvl),
          apr,
          volume24,
          tokenEntities: {
            fromTokenEntity: {
              ...coinX,
              logo: coinX.logoUrl,
              title: coinX.name,
              address: undefined,
            },
            toTokenEntity: {
              ...coinY,
              logo: coinY.logoUrl,
              title: coinY.name,
              address: undefined,
            },
          },
        } as IPoolBase,
        registerPoolOptions,
      );
    });

    fetchAccountLps();
  }

  const findRegisteredPool = computed(
    () =>
      (
        coinX: string | undefined,
        coinY: string | undefined,
        curve: string,
        contract?: number,
      ): IPersistedPool | undefined => {
        if (!coinX || !coinY) {
          return;
        }

        const isSorted = is_sorted(coinX, coinY);
        const [sortedX, sortedY] = isSorted ? [coinX, coinY] : [coinY, coinX];

        /**
         * FIXME: Due to the fact that there are a lot of watchers
         * (in the AddLiquidityContainer and useAddLiqidityStore)
         * that monitor the same data and mutate stores.
         * It is necessary to additionally synchronize the curve based on the version.
         */
        const shortCurveName = getShortCurveFromFull(curve);
        if (!shortCurveName) return;

        const syncedCurve = getCurve(shortCurveName, contract);
        if (!syncedCurve) return;

        const liquidityPool = getPoolStr(
          sortedX,
          sortedY,
          syncedCurve,
          contract,
        );

        return poolsMap[liquidityPool];
      },
  );

  /**
   * Get registered pool or register-fetch data and return it
   *
   * @param coinX string
   * @param coinY string
   * @param curve string
   * @param contract number | undefined,
   * @param mustBeRegistered boolean - prevents registration for non-created pools
   *                                   when changing curves and versions of pools
   *                                   in the main flows
   * @returns Promise<IPersistedPool>
   */
  const getPool = computed(
    () =>
      async (
        coinX: string,
        coinY: string,
        curve: string,
        contract?: number,
        isSaveInPool = true,
        recheckPool = true,
      ): Promise<IPersistedPool | undefined> => {
        let registeredPool = findRegisteredPool.value(
          coinX,
          coinY,
          curve,
          contract,
        );

        if (!registeredPool) {
          if (recheckPool) {
            await fetchPoolsList([
              {
                coinX,
                coinY,
                curve,
                networkId: BUILD_CHAIN_ID,
                contract: typeof contract === 'number' ? contract : VERSION_0_5,
              },
            ]);

            return getPool.value(
              coinX,
              coinY,
              curve,
              contract,
              isSaveInPool,
              false,
            );
          }

          const mainStore = useStore();
          const { networkId } = mainStore;
          registeredPool = await registerPool(
            {
              coinX,
              coinY,
              curve,
              networkId: networkId.value,
              contract,
              exceptional: false,
            },
            {
              rewrite: true,
              lazy: false,
              isSaveInPool,
            },
          );
        }

        return registeredPool;
      },
  );

  const defaultPools = computed(() =>
    Object.values(poolsMap).filter((pool) => pool.isDefault),
  );

  const filteredPoolsByExceptional = computed(() => {
    const exceptionalPools = Object.values(poolsMap).filter(
      (item) => item.exceptional,
    );

    const restPools = Object.values(poolsMap).filter(
      (item) => !item.exceptional,
    );

    const mappedPools = restPools.filter(
      (regularPool) =>
        !exceptionalPools.some(
          (exceptPool) =>
            regularPool.coinX === exceptPool.coinX &&
            regularPool.coinY === exceptPool.coinY,
        ),
    );

    return [...mappedPools, ...exceptionalPools];
  });

  // const poolsWithTokensEntities = computed(() => {
  //   const result = filteredPoolsByExceptional.value.map((item) => {
  //     return {
  //       ...cloneDeep(item),
  //       tokenEntities: {
  //         fromTokenEntity: tokensStore.getToken(item.coinX),
  //         toTokenEntity: tokensStore.getToken(item.coinY),
  //       },
  //     };
  //   });
  //   return result;
  // });

  const poolsMapAsArray = computed(() => Object.values(poolsMap));

  /**
   * Check whether this pair is in default pools and gets its type
   *
   * @param coinX
   * @param coinY
   * @returns
   */
  function getCurveType(
    coinX?: string,
    coinY?: string,
    version?: number,
  ): string | false {
    if (!coinX || !coinY) return false;
    const [sortedX, sortedY] = is_sorted(coinX, coinY)
      ? [coinX, coinY]
      : [coinY, coinX];
    const tokenPair = `${sortedX}-${sortedY}-${version}`;
    return predefinedCurves.stable.has(tokenPair)
      ? getCurve('stable', version)
      : predefinedCurves.unstable.has(tokenPair)
      ? getCurve('unstable', version)
      : false;
  }

  async function isPoolExistsInResource(
    resourceAccount: string,
    liquidityPool: string,
  ) {
    const pool = await aptos.client.getAccountResource(
      resourceAccount,
      liquidityPool,
    );

    return pool ? true : false;
  }

  /**
   * Gets default pools from coins-registry and sets specific data for them.
   */
  async function fetchDefaultPoolsData() {
    isLoading.value = true;
    return fetchPoolsList()
      .then(async () => {
        await fetchAccountLps();
        setShareOfPoolForAllPools();

        // TODO remove after testing LS-1907

        // if (mainStore.account.value?.address) {
        //   await fetchAndSetPooledFundsForAllPools(
        //     mainStore.account.value?.address,
        //   );
        // }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        isLoading.value = false;
        isReady.value = true;
      });
  }

  const hasLpCoins = computed(() =>
    Object.keys(poolsMap).reduce(
      (val, key) =>
        val || (poolsMap[key].lp !== undefined && poolsMap[key].lp > 0),
      false,
    ),
  );

  function getPoolFromMap(
    coinX: string,
    coinY: string,
    curve: string,
    version: number,
  ) {
    const key = getPoolStr(coinX, coinY, curve, version);
    return poolsMap[key];
  }

  const setPool = (savePool: IPersistedPool) => {
    const { coinX, coinY, contract, curve } = savePool;
    const liquidityPool = getPoolStr(coinX, coinY, curve, contract);
    if (poolsMap[liquidityPool]) {
      return;
    } else {
      poolsMap[liquidityPool] = savePool;
    }
  };

  /**
   * Store public interface
   */
  return {
    isLoading,
    isReady,
    poolsMap,
    getPool,
    getCurveType,
    loadPool,
    findRegisteredPool,
    fetchAndSetPooledFundsForAllPools,
    fetchPoolsList,
    loadPoolByType,
    fetchTotalLp,
    isPoolExistsInResource,
    fetchDefaultPoolsData,
    getPoolFromMap,
    pools,
    isLoadMyPools,
    poolsTitleMap,
    poolsMapAsArray,
    defaultPools,
    filteredPoolsByExceptional,
    stakingLpDontShow,
    lpCoins,
    fetchAccountLps,
    hasLpCoins,
    loaders,
    setPool,
  };
});
